import cx from 'classnames'
import { motion } from 'framer-motion'
import {
  forwardRef,
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useId,
  useMemo,
  useState,
} from 'react'

import { type SanityProductFragment } from '@data/sanity/queries/types/product'
import { itemAnimation } from '@lib/animate'
import { CartContext } from '@lib/cart/context'
import {
  getCollectionDiscounts,
  getProductDiscounts,
} from '@lib/discount/product'
import { getFilteredProductVariant } from '@lib/product/card'
import {
  getDocumentPercentDiscount,
  getProductPrices,
} from '@lib/product/price'
import { ProductQuickAddContext } from '@lib/product/quick-add-context'
import { type Filter } from '@lib/product/types'
import { getLinkPageUrl } from '@lib/routes'
import { StringsContext } from '@lib/strings-context'
import { UserContext } from '@lib/user/context'
import { getHasUserPurchasePermission } from '@lib/user/helpers'

import ProductPrice from '@blocks/product/product-price'
import ProductThumbnail from '@blocks/product/product-thumbnail'
import { getButtonStyles } from '@components/buttons/button'
import ProductStock from '@blocks/product/product-stock'
import SimpleLink from '@components/simple-link'
import Icon from '@components/icon'

interface ProductCardProps {
  product: SanityProductFragment
  showPrice?: boolean
  showQuickAdd?: boolean
  activeFilters?: Filter[]
  className?: string
  onClick?: () => void
}

const ProductCard = forwardRef<HTMLDivElement, ProductCardProps>(
  (
    { product, showPrice, showQuickAdd, activeFilters, className, onClick },
    ref
  ) => {
    const { cart, cartAddingProductIds } = useContext(CartContext)
    const { openProductQuickAdd } = useContext(ProductQuickAddContext)
    const strings = useContext(StringsContext)
    const { user } = useContext(UserContext)

    const id = useId()

    const [activeVariant, setActiveVariant] = useState(() =>
      getFilteredProductVariant(product, activeFilters ?? [])
    )

    const [price, comparePrice] = useMemo(() => {
      const productDiscounts = getProductDiscounts(
        user?.company?.productDiscounts
      )
      const collectionDiscounts = getCollectionDiscounts(
        user?.company?.collectionDiscounts
      )
      const productPercentDiscount = getDocumentPercentDiscount(
        productDiscounts ?? [],
        'product',
        product._id ? [product._id] : []
      )
      const collectionPercentDiscount = getDocumentPercentDiscount(
        collectionDiscounts ?? [],
        'collection',
        product.collectionIds ?? []
      )

      return getProductPrices({
        productPrice: product.price,
        productComparePrice: product.comparePrice,
        variantPrice: activeVariant?.price,
        variantComparePrice: activeVariant?.comparePrice,
        productPercentDiscount,
        collectionPercentDiscount,
        companyPercentDiscount: user?.company?.percentDiscount,
      })
    }, [activeVariant, product, user])

    useEffect(
      () =>
        setActiveVariant(
          getFilteredProductVariant(product, activeFilters ?? [])
        ),
      [activeFilters, product]
    )

    const handleQuickAddClick = useCallback(
      (event: MouseEvent<HTMLButtonElement>) => {
        // Prevent immediate closing of quick add modal by stopping click event propagation
        event.stopPropagation()

        openProductQuickAdd(product)
      },
      [openProductQuickAdd, product]
    )

    const galleryPhotos = product.galleryPhotos ?? []
    const listingPhotos = product.listingPhotos ?? []

    const hasThumbnails = listingPhotos.length > 0
    const hasUserPurchasePermission = getHasUserPurchasePermission(user)
    const hasQuickAdd =
      showQuickAdd &&
      !!activeVariant?.inStock &&
      cart &&
      hasUserPurchasePermission
    const hasPrice =
      showPrice && typeof price !== 'undefined' && hasUserPurchasePermission

    // Get product URL
    const productUrl = getLinkPageUrl({
      pageType: 'product',
      slugs: product.slug.current,
    })

    const isAddingToCart = activeVariant
      ? cartAddingProductIds.includes(activeVariant.variantID)
      : false

    return (
      <motion.div
        ref={ref}
        variants={itemAnimation}
        className={cx('flex flex-col gap-4 group', className)}
      >
        {activeVariant && hasThumbnails && (
          <div className="relative border">
            <SimpleLink
              href={productUrl}
              onClick={onClick}
              onBeforeInput={onClick}
              tabIndex={0}
            >
              <ProductThumbnail
                galleryPhotos={galleryPhotos}
                listingPhotos={listingPhotos}
                activeVariant={activeVariant}
              />
            </SimpleLink>

            {hasQuickAdd && (
              <div className="absolute bottom-2 right-2 group/quick-add">
                <button
                  disabled={!cart || isAddingToCart}
                  onClick={handleQuickAddClick}
                  className={cx(
                    'group',
                    getButtonStyles({
                      variant: 'outlined',
                      size: 'xs',
                      color: 'gray',
                      icon: 'Plus',
                    }),
                    'relative z-[1] bg-white'
                  )}
                >
                  <span className="text-[20px]">
                    <Icon
                      id={`product-quick-add-plus-icon-${id}`}
                      name="Plus"
                    />
                  </span>
                </button>
                <span
                  className={cx(
                    'absolute -right-[3px] -top-[3px] pl-[18px] pr-[42px]',
                    'rounded-full leading-[36px] font-medium text-xs sm:text-[14px] whitespace-nowrap bg-[rgba(34,34,34,.7)] text-white',
                    'opacity-0 group-hover/quick-add:opacity-100 transition-all'
                  )}
                >
                  {strings.buttonSeeOptions}
                </span>
              </div>
            )}
          </div>
        )}

        <div className="space-y-2">
          <div className="">
            <SimpleLink
              className="block is-h4 mb-1"
              href={productUrl}
              onClick={onClick}
              onBeforeInput={onClick}
              tabIndex={0}
            >
              {product.title}
            </SimpleLink>

            {hasPrice && (
              <ProductPrice
                className="flex items-center justify-center"
                price={price}
                comparePrice={comparePrice}
                variant="sm"
                showOldPrice
              />
            )}

            {!!activeVariant && (
              <>
                <p className="text-sm">SKU: {activeVariant.sku}</p>
                <ProductStock productVariant={activeVariant} />
              </>
            )}
          </div>
        </div>
      </motion.div>
    )
  }
)

ProductCard.displayName = 'ProductCard'

export default ProductCard
