import { useCallback, useContext, useState } from 'react'

import { type AttributeInput } from '@data/shopify/admin/types'
import { AnalyticsEventName } from '@lib/analytics'
import { AnalyticsContext } from '@lib/analytics-context'
import { CartContext } from '@lib/cart/context'
import { useAddItemsToCart } from '@lib/cart/hooks'
import { LanguageContext } from '@lib/language-context'
import { getOrCreateCombinedListingProduct } from '@lib/product/combined-listing'
import { parseShopifyGlobalId } from '@lib/shopify/helpers'
import { StringsContext } from '@lib/strings-context'

import Alert from '@components/alert'
import SimplePortableText from '@components/simple-portable-text'
import ProductAddToCart from './product-add-to-cart'
import ProductCounter from './product-counter'

interface CombinedListingProductActionsProps {
  productIds: number[]
  variantIds: number[]
  size: string
  isEmptyFrame: boolean
  cartAttributes?: AttributeInput[]
}

const CombinedListingProductActions = ({
  productIds,
  variantIds,
  size,
  isEmptyFrame,
  cartAttributes,
}: CombinedListingProductActionsProps) => {
  const { locale } = useContext(LanguageContext)
  const strings = useContext(StringsContext)
  const { setIsCreatingProduct } = useContext(CartContext)
  const { triggerAnalyticsEvent } = useContext(AnalyticsContext)

  const [quantity, setQuantity] = useState(1)
  const [isAddToCartError, setIsAddToCartError] = useState(false)

  const addItemsToCart = useAddItemsToCart()

  const handleAddToCart = useCallback(async () => {
    setIsAddToCartError(false)

    // Use variant ID directly, if is an empty frame
    let variantId = isEmptyFrame ? variantIds[0] : undefined

    // Create a combined listing product and use its variant ID, if isn't an empty frame
    if (variantIds.length > 1 && !isEmptyFrame) {
      setIsCreatingProduct(true)

      const combinedListingProduct = await getOrCreateCombinedListingProduct(
        locale,
        productIds,
        size
      )
      variantId = combinedListingProduct?.variantId
        ? parseShopifyGlobalId(combinedListingProduct.variantId)
        : undefined

      setIsCreatingProduct(false)
    }

    setIsAddToCartError(!variantId)

    if (!variantId) {
      return
    }

    // Add combined listing product to cart
    const isSuccessful = await addItemsToCart([
      {
        id: variantId,
        quantity,
        attributes: cartAttributes,
      },
    ])

    setIsAddToCartError(!isSuccessful)

    if (!isSuccessful) {
      return
    }

    triggerAnalyticsEvent(AnalyticsEventName.AddToCart)
  }, [
    addItemsToCart,
    cartAttributes,
    isEmptyFrame,
    locale,
    productIds,
    quantity,
    setIsCreatingProduct,
    size,
    triggerAnalyticsEvent,
    variantIds,
  ])

  return (
    <div className="flex flex-col gap-y-5 items-baseline w-full text-center">
      <ProductCounter max={10} onUpdate={setQuantity} />

      <ProductAddToCart
        variant="filled"
        disabled={variantIds.length === 0}
        onClick={handleAddToCart}
        className="max-w-sm w-full"
      >
        {strings.buttonAddToCart}
      </ProductAddToCart>

      {isAddToCartError && (
        <div key="error">
          <Alert error>
            <SimplePortableText
              className="rc rc-alert rc-error"
              content={strings.cartAddToCartErrorMessage}
            />
          </Alert>
        </div>
      )}
    </div>
  )
}

export default CombinedListingProductActions
